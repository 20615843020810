import { useContext, useMemo } from 'react'
import { FiShoppingBag } from 'react-icons/fi'
import { CartContext } from '../../providers/CartContext'
import Link from 'next/link'
import tw from 'tailwind-styled-components'
import { ICollectionFields } from '../../@types/generated/contentful'

const Header = tw.header`
  sticky
  top-0
  z-30
  bg-background
`

const Container = tw.div`
  container
  mx-auto
`

const TopWrapper = tw.div`
  h-16
  p-4
  grid
  grid-cols-[auto_1fr_auto]
`

const LogoLink = tw.a`
  block
`

const Logo = tw.img`
  relative
  block
`

const CartWrapper = tw.div`
  grid
  grid-cols-[auto_auto_auto]
  gap-2
  items-center
  cursor-pointer
  relative
`

const CartLabel = tw.span`
  text-base
`

const Navigation = tw.nav``

const NavigationList = tw.ul`
  shadow-xl
  bg-teatips-light
  rounded
  list-none
  px-4
  m-0
  whitespace-nowrap
  md:whitespace-normal
  overflow-x-auto
  md:overflow-x-hidden
  md:grid
  md:grid-flow-col
  md:auto-cols-max
  md:justify-evenly
`

const NavigationItem = tw.li`
  inline-block
  md:block
`

interface ItemAnchorProps {
  $first: boolean;
  $last: boolean;
}

const ItemAnchor = tw.a<ItemAnchorProps>`
  text-text
  no-underline
  block
  text-base
  py-2
  pl-2
  pr-2
  ${(p: ItemAnchorProps) => (p.$first ? `pl-0 pr-2` : '')}
  ${(p: ItemAnchorProps) => (p.$last ? `pl-2 pr-0` : '')}  
`

const ShopHeader = ({ collections }: { collections: ICollectionFields[] }) => {
  const orderedCollections = useMemo(() => {
    const ordered = collections.slice().sort((a: ICollectionFields, b: ICollectionFields) => {
      return (
        (a.menuOrder || Number.MAX_SAFE_INTEGER) -
        (b.menuOrder || Number.MAX_SAFE_INTEGER)
      )
    })

    return ordered
  }, [collections])

  const { dispatch } = useContext(CartContext)

  return (
    <Header>
      <Container>
        <TopWrapper>
          <Link href="/" passHref>
            <LogoLink>
              <Logo
                src={require('./logo.svg').default.src}
                alt="Logo di Teatips"
                width={170}
                height={32}
              />
            </LogoLink>
          </Link>

          <div></div>

          <CartWrapper onClick={() => dispatch({ type: 'OPEN' })}>
            <CartLabel>Carrello</CartLabel>
            <FiShoppingBag className="text-xl" />
          </CartWrapper>
        </TopWrapper>

        <Navigation>
          <NavigationList>
            {orderedCollections.map((collection: ICollectionFields, i: number) => (
              <NavigationItem key={i}>
                <Link
                  passHref
                  href={`/collections/${collection.slug}`}
                  key={collection.slug}
                >
                  <ItemAnchor
                    $first={i === 0}
                    $last={i === orderedCollections.length - 1}
                  >
                    {collection.title}
                  </ItemAnchor>
                </Link>
              </NavigationItem>
            ))}
          </NavigationList>
        </Navigation>
      </Container>
    </Header>
  )
}

export default ShopHeader
